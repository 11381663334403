// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-examples-mdx": () => import("./../../../../src/pages/examples.mdx" /* webpackChunkName: "component---src-pages-examples-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-indexing-mdx": () => import("./../../../../src/pages/indexing.mdx" /* webpackChunkName: "component---src-pages-indexing-mdx" */),
  "component---src-pages-scenarios-mdx": () => import("./../../../../src/pages/scenarios.mdx" /* webpackChunkName: "component---src-pages-scenarios-mdx" */),
  "component---src-pages-sharing-mdx": () => import("./../../../../src/pages/sharing.mdx" /* webpackChunkName: "component---src-pages-sharing-mdx" */)
}

